// App.js
import * as React from 'react';
import { Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./styles/App.css";
import Get from './components/get.js';

import paramList from './data/parameters.json'
import responsesList from './data/responses.json'
import props from './data/props.json'

function App() {
  const keys = Object.keys(props)

  keys.forEach((key, index) => {
    props[key].responses = responsesList[key]
    props[key].params = paramList[key]
  })

  return (
    <div className="App">
      <header className="App-header">

        <div className='title'>
          <span className='titleText'>Astroid API</span>
          <div className='ver'>2.1.4</div>
          <div className='oas'>OAS 3.1</div>
        </div>

        <div className='path'>/openapi.json</div>
        <div className='apiDescription'>Astroid API for getting and modifying endpoints.</div>
        <div className='accordionBox'>
          <Accordion className='accordion accordion-get'>

            { /* Accordion header */}
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className='accordion-summary'>GET</div>
            </AccordionSummary>

            { /* Accordion body */}
            <AccordionDetails>
              <Stack spacing={1}>
                <Get {...props.root} />
                <Get {...props.invite} />
                <Get {...props.privacyDc} />
                <Get {...props.privacy} />
                <Get {...props.terms} />
                <Get {...props.getServerStructure} />
                <Get {...props.getEndpoint} />
                <Get {...props.getBridges} />
              </Stack>
            </AccordionDetails>

          </Accordion>
          <Accordion className='accordion accordion-post'>

            { /* Accordion header */}
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className='accordion-summary'>POST</div>
            </AccordionSummary>

            { /* Accordion body */}
            <AccordionDetails>
              <Stack spacing={1}>
                <Get {...props.newToken} />
                <Get {...props.postEndpoint} />
                <Get {...props.markRead} />
                <Get {...props.createEndpoint} />
              </Stack>
            </AccordionDetails>

          </Accordion>
          <Accordion className='accordion accordion-delete'>

            { /* Accordion header */}
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className='accordion-summary'>DELETE</div>
            </AccordionSummary>

            { /* Accordion body */}
            <AccordionDetails>
              <Stack spacing={1}>
                <Get {...props.deleteEndpoint} />
                <Get {...props.deleteEndpointData} />
              </Stack>
            </AccordionDetails>

          </Accordion>
        </div>
      </header>
    </div>
  );
}

export default App;
