import * as React from 'react';

// Item presets
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tab,
  Box
} from '@mui/material';

// Tablist
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab'

import "../styles/getComponent.css"

function Get({ params, route, name, method, responses, description }) {
  const [value, setValue] = React.useState('1');

  // Handle panel change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Route list
  let routeElements = [];
  if (route) {
    // Appends route to list
    routeElements = route.split("/").map((param, index) => (
      <div key={index}><span>/</span><span>{param}</span></div>
    ));
    routeElements.shift()
  } else {
    routeElements = [<span>/</span>];
  }

  // Classnames based on method (for style)
  const ClassNameButton = method.toLowerCase() + "Button"
  const ClassNameSection = method.toLowerCase() + "section"

  // Parameter list
  let paramlist
  if (params) {
    // Appends parameters to list
    paramlist = params.map((param, index) => (
      <TableRow>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.name }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.description }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.urlType }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.type }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.minLength }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.maxLength }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.default.toString() }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ param.required.toString() }</TableCell>
      </TableRow>
    ))
  } else paramlist = 1

  // Responses list
  let responseslist
  if (responses) {
    // Appends responses to list
    responseslist = responses.map((response, index) => (
      <TableRow>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ response.code }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ response.desription }</TableCell>
        <TableCell style={{borderColor: "darkgray", color: "lightgray"}}>{ response.mediaType }</TableCell>
      </TableRow>
    ))
  }

  return (
    <Accordion style={{ backgroundColor: "#1D202B" }}>
      <AccordionSummary>
        <Stack className={ClassNameSection}>
          <button>
            <span className={ClassNameButton}>{method}</span>
              {routeElements}
            <div className='description'>{name}</div>
          </button>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '100%', typography: 'body1', backgroundColor: "#151720" }}>
          <TabContext value={value}>

            { /* Tabs */ }
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab style={{color: 'white'}} label="Description" value="1" />
                <Tab style={{color: 'white'}} label="Parameters" value="2" />
                <Tab style={{color: 'white'}} label="Responses" value="3" />
              </TabList>
            </Box>

            { /* Description Tab */ }
            <TabPanel value="1">
              <span style={{ color: "white", display: "flex" }}>{description}</span>
            </TabPanel>

            { /* Parameters Tab */ }
            <TabPanel value="2">
              {paramlist === 1 ?
                <span style={{ color: "white", display: "flex" }}>No Parameters</span>
              :
                <TableContainer component={Paper} sx={{backgroundColor: "#1D202B"}}>
                  <Table sx={{backgroundColor: "#1D202B"}}>

                    { /* Table Headers */ }
                    <TableHead>
                      <TableRow>
                        <TableCell style={{color: "white", fontWeight: 900}}>Name</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>Description</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>URL-Type</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>Type</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>Min Length</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>Max Length</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>Default</TableCell>
                        <TableCell style={{color: "white", fontWeight: 900}}>Required</TableCell>
                      </TableRow>
                    </TableHead>

                    { /* Table Body */ }
                    <TableBody> {paramlist} </TableBody>

                  </Table>
                </TableContainer>
              }
            </TabPanel>

            { /* Responses Tab */ }
            <TabPanel value="3">
              <TableContainer component={Paper} sx={{backgroundColor: "#1D202B"}}>
                <Table sx={{backgroundColor: "#1D202B"}}>

                  { /* Table Headers */ }
                  <TableHead>
                    <TableRow>
                      <TableCell style={{color: "white", fontWeight: 900}}>Code</TableCell>
                      <TableCell style={{color: "white", fontWeight: 900}}>Description</TableCell>
                      <TableCell style={{color: "white", fontWeight: 900}}>Media Type</TableCell>
                    </TableRow>
                  </TableHead>

                  { /* Table Body */ }
                  <TableBody> {responseslist} </TableBody>

                </Table>
              </TableContainer>
            </TabPanel>

          </TabContext>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Get;
